.question {
  flex: 2;
  /* margin-left: 25px; */
  /* padding-right: 25px; */
  margin: 15px;
}

.questionForm {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
}

.subTitle h2{
  flex-direction:row;
}

.completed{
  display: flex;
  flex-direction:column;
  width: 100%;
  margin: 12%;
  color: #662d90;
  text-align: center;
}

.subText {
  display: flex;
  width: 80%;
  border-radius: 0px 55px 55px 0px;
  font-size: 18px;
  padding: 10px;
  background: #662d90;
  color: white;
  margin: 0;
}

.questionItem {
  width: 35%;
  display: flex;
  flex-direction: column;
  margin: 25px;
}

.questionItem > label {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  color: #2d2d2d;
}

.questionItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 80%;
} 

.UserSelect {
  height: 41px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 80%;
} 

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(181, 181, 181);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(181, 181, 181);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(181, 181, 181);
}

.questionaryRadio > input {
  margin-top: 15px;
}

.questionaryRadio > label{
    margin: 10px;
    font-size: 16px;
    color: #555;
}

.orina {
  width: 100%;
}

.orina > label {
  margin-right: 19.5px;
}

.questionMultiple > table {
  /* width: 100%; */
  min-width:90%;
}

.questionMultiple > table > tr {
  background-color:none;
  text-align: center; 
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}

.advice {
  font-size: 12px;
  position: absolute;
  text-align: center; 
  width: 100%;
  color: red;
  padding-bottom: 15px;
}

.nombre{
  text-align: left !important; 
}

.questionMultiple > table > tr > td > input {
  margin: 10px;
}

.questionMultiple > table > tr > td > label{
    font-size: 80%;
    color: #555;
}

.sendButton{
    background: #662d90;
    font-weight: 900;
}

.sendButton:hover{
  background: #c16bff;
  font-weight: 900;
}


.encabezado {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.inputOtros {
  width: 50%;
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.validation {
  font-size: 75%;
  color: red;
  font-weight: 900;
}

.SigButton.MuiButton-contained {
  background-color: '#662d90'
}

.SigButton.MuiButton-contained:hover {
  background-color: #c16bff;
}

.AntButton.MuiButton-outlined {
  color: #29aae1;
  background-color: white;
  border-color: #29aae1;
}

.AntButton.MuiButton-outlined:hover {
  color: white;
  background-color: #29aae1;
  border-color: #29aae1;
}

.EnviarButton.MuiButton-contained {
  background-color: '#f05a24'
}

.EnviarButton.MuiButton-contained:hover {
  background-color: #ffa100;
}

.MuiStepIcon-active {
  color: green;
}

.MuiStepIcon-completed {
  color: blue;
}

@media only screen and (max-width: 643px) {
  .questionForm {
    display: inline;
  }

  .subText {
    width: 100%;
  }

  .questionItem {
    width: 80%;
  }

  .questionItem > input {
    width: 80%;
  } 

  .UserSelect {
    width: 80%;
  } 

  .imgOrina {
    width: 100%;
  }

  .questionMultiple > table > tr > td > label{
    font-size: 70%;
  }

  .sendButton{
    background: none;
    font-weight: 900;
  }
}