.sidebar {
  flex: 1;
  height: calc(120vh - 50px);
  background-color: #fff;
  position: sticky;
  top: 50px;
  box-shadow: 0px 0px 10px gray;
}

.sidebarWrapper {
  padding: 20px;
  color: #f05a24;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 15px;
  color: #f05a24;
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.5s;
}

.sidebarListItem:hover {
  background-color: rgba(255, 161, 0, 0.2);
  transition: background-color 0.5s;
}

.sidebarIcon{
  margin-right: 5px;
  font-size: 32px !important;
}

@media (max-width: 767px) {
  .sidebar {
    width: 100% !important;
  }
}