.topbar {
  width: 100%;
  height: 70px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0px 0px 15px gray;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.logo img {
  width: 200px;
  /* height: 20%; */
}

.topRight{
    display: flex;
    align-items: center;
}

#nav ul {
  list-style: none;
  text-decoration: none;
  /* margin: 0px auto; */
  /* width: 1090px; */
  padding: 0;
}

#nav > ul > li {
  line-height: 70px;
  float: right;
  border-left: 1px solid #f05a24;
}

#nav > ul > li label {
  display: block;
  padding-left: 30px;
  padding-right: 30px;
  color: #f05a24;
  text-decoration: none;
}

#nav > ul > li > label:first-child{
  border-right: 1px solid #f05a24;
}

#nav > ul > li label:hover{
  color: #ffa100;
  transition: color 300ms;
}

#nav > ul > li > ul {
  display: none;
  position: absolute;
  right: 20px;
  text-align: left;
  width: 209px;
  box-shadow: 0px 2px 2px gray;
  z-index: 1;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
  background-color: white;
  margin-left: 0px;
}

#nav > ul > li:hover > ul{
  display: block;
}

#nav > ul > li:hover > ul > li{
  /* background-color: white; */
  padding: 8px;
  line-height: 25px;
  font-size: 13px;
  transition: all 300ms;
}

#nav > ul > li:hover > ul > li label {
  color: #666;
}

#nav > ul > li:hover > ul > li:hover{
  box-shadow: 0px 0px 6px #ccc inset;
}

.textBar {
  text-decoration: none;
  color: #ffa100;
}

.textBar:hover {
  text-decoration: none;
  color: #f05a24 ;
  transition: color 200ms;
}

.iconProfile{
  margin-top: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) { 
  .name {
    display: none;
  }

  .iconProfile{
    font-size: x-large;
  }
}