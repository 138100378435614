.container{
    display: flex;
    margin-top: 0px;
    background-color: rgb(240, 240, 240);
    justify-content: space-between;
    background-image: url("img/roberto-sorin-RS0-h_pyByk-unsplash.jpg");
    background-size: cover;
}

.link{
    text-decoration: none;
    color: inherit;
}

.title {
    letter-spacing: 1px;
    color: #662d90;
    font-size: 42px;
    font-weight: 900;
    margin-top: 35px;
    margin-bottom: 15px;
    display: block;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff;
}

.subtitle {
    letter-spacing: 1px;
    color: #662d90;
    font-size: 21px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 15px;
    display: block;
    font-family: 'Roboto', sans-serif;
}

.bigButton {
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    background: #662d90;
    color: white;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 25px;
    transition: background-color 0.2s ease;
}

.bigButton:hover {
    box-shadow: 0px 0px 5px gray;
    background-color: #c16bff;
    transition: background-color 0.2s ease;
}

.smallButton {
    border-color: #29aae1;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: white;
    color: #29aae1;
    cursor: pointer;
    transition: background-color 0.2s ease;
    transition: color 0.2s;
}

.smallButton:hover{
    box-shadow: 0px 0px 5px gray;
    background-color: #29aae1;
    color: white;
    transition: background-color 0.2s ease;
    transition: color 0.2s;
}

.iconBack{
    margin: 0px;
    font-size: 40px;
    margin-top: 5px;
    color: #662d90;
}

.iconBack:hover{
    margin: 0px;
    font-size: 45px;
    margin-top: 5px;
    color: #c16bff;
    transition: background-color 0.2s ease;
    transition: color 0.2s;
}

#submit:disabled{
    cursor: not-allowed;
    background-color: gray !important;
}

.MuiTableRow-root:nth-child(odd) {
    background-color: #f2f2f2; /* Color de fondo para filas pares */
}

.MuiTableRow-root:nth-child(even) {
    background-color: #ffffff; /* Color de fondo para filas impares */
}

.MuiTableCell-root {
    border: 1px solid #ccc; /* Agrega un borde de 1px alrededor de cada celda */
    padding: 8px; /* Ajusta el espaciado interno de las celdas según sea necesario */
}

.MuiTableRow-root {
    border-bottom: 1px solid #ccc; /* Agrega un borde inferior de 1px a cada fila */
}

.MuiTableHead-root .MuiTableRow-root {
    border-top: 2px solid #000; /* Agrega un borde superior de 2px a la fila de encabezado */
}

.MuiTableHead-root .MuiTableRow-root:not(:last-child) {
    border-bottom: 1px solid #ccc; /* Agrega un borde inferior de 1px a las filas de la cabecera, excepto la última fila */
}