.home{
    flex: 4;
}

.welcome {
    display: flex;
    /* margin-top: 5%; */
}

.home-Img {
    margin-bottom: 25px;
    width: 35%;
    height: 35%;
    border-radius: 10px;
}

.home h1 {
    padding: 15px;
    margin-top: 35px;
    margin-left: 80px;
    font-size: 30px;
    font-weight: 300;
} 

.home h2 {
    padding: 15px;
    font-style: italic;
    font-weight: 600;
    font-size: 50px;
}

.homeCard {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px;
    padding: 0px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    
}

@media (max-width: 767px) {
    .home{
        flex: 0;
    }

    .homeCard {
        display: none;
    }
}