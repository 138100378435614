.examenes {
  flex: 4;
  margin-left: 25px;
  padding-right: 25px;
}

.examenesForm {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.examenesItem {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-right: 20px;
}

.examenesItem > label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
}

.examenesItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.examenesItem > select {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.examenesButton{
  width: 30%;
  height: 50px;
  margin: 15px;
  margin-top: 30px;  
}

.separador{
  background-color:#662d90;
  width:100%;
  color:white; 
  padding:5px; 
  text-align:center;
}

.fileButton {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #662d90;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
}

.alignButton {
  display:flex;
  align-items: center;
  justify-content: center;
}