.readUser {
  flex: 4;
  margin-left: 25px;
}

.readUserUser {
  display: flex;
  align-items: center;
}

.readUserImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.readUserEdit{
    margin-right: 20px;
}

.readUserDelete{
    color: Coral;
    cursor: pointer;
}

.UserListActiveOff{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: rgb(140, 140, 140);
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.UserListActiveOff:hover {
  background-color: rgb(198, 198, 198);
}

.UserListActiveOn{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #0071BC;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.UserListActiveOn:hover {
  background-color: #99E1DF;
}