.password {
  flex: 4;
  flex-direction: column;
  display:flex;
  justify-content: center;
}

.passwordForm {
  border: 1px solid #ffa100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 15%;
  border-radius: 5px;
  height: 35%;
  width: 50%;
  text-align: center;
  background-color: white;
}

.passwordTable{
  height: 100%;
  width: 100%;
}

.passwordTable > tr td {
  height: 50px;
}
.passwordTable > tr > td > label {
  color: #f05a24;
  font-weight: 600;
}

.passwordItem > td > label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
  margin: 10px;
}

.passwordItem > td > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.forgetButton {
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  color: #555;
  text-decoration: none;
}

.passwordButton{
    width: 70%;
    border: none;
    background: #f05a24;
    color: white;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}

.passwordButton:hover {
  box-shadow: 0px 0px 5px gray;
  background: #ffa100;
}

#user, #pass {
  width: 70%;
  height: 35px;
  border-radius: 5px;
  margin: 10px;
}

#validation {
  color: coral;
  font-weight: 600;
  font-style: italic;
}