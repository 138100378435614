.login {
  display: flex;
  align-items: center;
  height: 100vh;
}

.logo-Login {
  width: 35%;
  padding: 15px;
  padding-bottom: 25px;
}

.login-Img{
  width: 50%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  position: absolute;
}

.i-right{
  flex: 1;
  position: relative;
}

.i-left{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue-text {
  color: #f05a24;
  font-weight: 600;
  font-size: 21px;
}

.loginForm {
  /* display: 2; */
  border: 1px solid #ffa100;
  /* justify-content: center;
  align-items: center; */
  padding: 2%;
  margin: 3% 25% 3% 25%; /*top right bottom left*/
  border-radius: 5px;
  height: 100%;
  width: 50%;
  text-align: center;
  background-color: white;
}

.loginTable{
  height: 100%;
  width: 100%;
}

.loginTable > tr td {
  height: 50px;
}
.loginTable > tr > td > label {
  color: #ffa100;
  font-weight: 600;
}

.loginItem > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
  margin: 10px;
}

.loginItem > input {
  height: 50px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.loginButton{
    width: 50%;
    border: none;
    background: #f05a24;
    color: white;
    padding: 1.5%;
    font-weight: 600;
    border-radius: 10px;
    margin: 2%;
    cursor: pointer;
}

.loginButton:disabled{
  background: gray;
  cursor: not-allowed;
}

.loginButton:hover{
  background: #ffa100;
  box-shadow: 0px 0px 5px gray;
}

.loginItem > .forgetButton {
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: #ffa100;  
  cursor: pointer;
}

#user, #pass {
  width: 70%;
  height: 35px;
  border-radius: 5px;
  margin: 10px;
}

#validation {
  color: coral;
  font-weight: 600;
  font-style: italic;
}

footer {
  text-align: center;
}

footer span {
  color: #f05a24;  
  font-size: 14px;
  cursor: pointer;
}