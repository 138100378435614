.summary {
  flex: 4;
  padding: 20px;
}

.summaryTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summaryAddButton {
  width: 80px;
}

.summaryContainer {
  display: flex;
  margin-top: 20px;
}

.summaryShow {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.summaryUpdate {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.summaryShowTop {
  display: flex;
  align-items: center;
}

.summaryShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.summaryShowsummaryname {
  font-weight: 600;
}

.summaryShowsummaryTitle {
  font-weight: 300;
}

.summaryShowBottom{
    margin-top: 20px;
}

.summaryShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.summaryShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.summaryShowIcon{
    font-size: 16px !important;
}

.summaryShowInfoTitle{
    margin-left: 10px;
    font-size: 14px;
}

.summaryShowInfoText{
  margin-left: 10px;
  font-size: 12px;
  font-weight: 300;
}

.summaryUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}

.summaryUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.summaryUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.summaryUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}

.summaryUpdateInput{
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

.summaryUpdateLeft{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.summaryUpdateRight{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.summaryUpdateUpload{
    display: flex;
    align-items: center;
}

.summaryUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.summaryUpdateIcon{
    cursor: pointer;
}

.Details {
  padding-top: 25px;
  margin: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.commentary {
  color: #444;
}

details>label {
  margin-bottom: 5px;
  font-size: 14px;
}

.divButton button{
  flex-direction: row;
}

.reportButton{
  width: 28%;
  height: 50px;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.table {
  width: 100%;
  border: solid 1px rgb(175, 170, 170);
  border-radius: 10px;
  font-size: 12px;
  margin-top:5px;
}

.themeName {
  color: #444;
  font-size: 24px;
  font-weight: 600;
}