.examReport {
  flex: 4;
  margin-left: 25px;
  padding-right: 25px;
}

.examReportForm {
  display: flex;
  flex-wrap: wrap;
}

.examReportItem {
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  margin-right: 20px;
}

.examReportItem > label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #2d2d2d;
}

.examReportItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.examReportButton{
    width: 96%;
    height: 50px;
    border: none;
    /* background-color: #0071BC; */
    background: linear-gradient(to right, #0071BC 0%, #99E1DF 100%);
    color: white;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
  
}

.examReportButton:hover {
  box-shadow: 0px 0px 5px gray;
  background: linear-gradient(to right, #99E1DF 0%, #ccf6f5 100%);
}