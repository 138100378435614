.readLab {
  flex: 4;
  margin-left: 25px;
}

.readLabLab {
  display: flex;
  align-items: center;
}

.readLabImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.readLabEdit{
    margin-right: 20px;
}

.readLabDelete{
    color: Coral;
    cursor: pointer;
}

.LabListActiveOff{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: rgb(140, 140, 140);
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.LabListActiveOff:hover {
  background-color: rgb(198, 198, 198);
}

.LabListActiveOn{
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #0071BC;
  color: white;
  cursor: pointer;
  margin-right: 20px;
}

.LabListActiveOn:hover {
  background-color: #99E1DF;
}

.newExamLab{
  margin-bottom: 20px;
  margin-top: 10px;
}